.dashboard-toolbar.auth-header{
    left: 0;
}

.d-logo {
    margin-bottom: 0px;
    margin-top: 0px;
}
.login-heading{
    padding-top: 70px !important;
}
