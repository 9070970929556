@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');

body {
    font-family: 'Inter', sans-serif;
}
.font-family {
    font-family: 'Inter', sans-serif;
}
h1{
    color: green;
}
.Profile-img {
    background: linear-gradient(180deg, #458DFC 0%, #A9F2FF 100%);
    border-radius: 50%;
}
.header-nav{
    padding: 5px 0px;
}
.user-dropdown .nav-link {
    margin: 0px 10px;
}
.user-dropdown .nav-link.active {
    font-family: 'Inter', sans-serif;
    background: #ECF4FF;
    border-radius: 5px;
    color: #3771CA;
}
.user-dropdown .nav-link:hover {
    font-family: 'Inter', sans-serif;
    background: #ECF4FF;
    border-radius: 5px;
    color: #3771CA;
}
.progress-value p{
    font-weight: 500;
    font-size: 12px;
    line-height: 0px;
}
.progress-value h6{
    font-weight: 500;
    font-size: 24px;
    line-height: 20px;
}
.span.step-circle.step-circle-active.step-circle-Complete::after {
    height: 10px;
    width: 10px;
    top: 1px;
}


/*responsive-13-7- */
@media all and (min-width:320px) and (max-width: 767px) {
.fourthStep .NewStepThird-p1 .agreeWrap span {
        display: block;
}
.stepsWrapSteps {
    min-height: 30px;
    display: inline-block;
    width: 14.2857143%;
    padding: 0px;
    text-align: center;
}
.stepsWrapSteps .divCricle {    
    width: 100%;
}
.stepsWrapSteps .divCricle::before {
    width: 100%;
    height: 2px;
    top: 17px;
    left: 47%;
}
.stepsWrapSteps h6 {
    position: absolute;
    top: 42px;
    padding: 0px !important;
    width: 100%;
    font-size: 12px !important;
}
.stepsWrapSteps p {
    display: none;
}
.stepsWrapSteps:last-child .divCricle::before {
    display: none;
}
.col-md-3.newMenteeWizardSidebar {
    min-height: 150px;
}
.newMenteeWizardSection .wizardWidthWrap .mt-80 {
    margin-top: 20px;
}
}
/*responsive-13-7- */
