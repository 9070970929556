.borders {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
  width: 50px;
}
.borders:after,
.borders:before {
  border: 6px solid #0d6efd;
  border-radius: 100%;
  position: absolute;
  content: '';
  display: block;
}
.borders:before {
  border-bottom-color: transparent;
  border-left-color: transparent;
  animation: spin 0.75s infinite linear reverse;
  height: 30px;
  width: 30px;
}
.borders:after {
  -webkit-animation: spin 0.5s infinite linear;
  animation: spin 0.5s infinite linear;
  height: 50px;
  width: 50px;
  border-right-color: transparent;
  border-top-color: transparent;
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
